@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');


html {
    font-family: "Figtree", sans-serif;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@layer components {
    .hover-lift {
        @apply transition hover:scale-105 hover:shadow-xl;
    }

    .btn-hover {
        @apply transition hover:text-white hover:bg-brand-500;
    }

    .html-display ul {
        @apply list-disc ml-5 px-2 pt-2
    }

    .markup p {
        margin-bottom: 1rem;
    }

    .markup ul {
        list-style-type: disc;
        margin-left: 1rem;
        padding: .25rem .5rem 0 .5rem;
    }

    .markup ul ul {
        list-style-type: circle;
    }
}
